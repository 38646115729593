import type { CheckUserAcessProps } from "~/plugins/auth";
import { UserRoles } from "~/types";

export { Actions } from "@/constants/permissions";

export const hasPermission = (props: CheckUserAcessProps) => {
  const { $auth } = useNuxtApp();
  return $auth.checkUserAccess(props);
};

export const isOperationalOrAdmin = () => {
  const { $auth } = useNuxtApp();
  return $auth.checkUserAccess({
    roles: [UserRoles.OPERATIONAL, UserRoles.ADMIN],
  });
};

export const accessMiddleware = (permissions: CheckUserAcessProps) => {
  const router = useRouter();
  const { $auth } = useNuxtApp();
  if (!$auth.checkUserAccess(permissions)) {
    return router.replace("/");
  }
};
