import { PolicyType, PolicyStatus, type InternalPolicy } from "~/types";
import type { SendPolicyFormFields } from "~/components/molecules/contract/view/policies/forms/send-policy-form.vue";
import { hiringFlowApi } from "~/api/hiring-flow";
import { proposalApi } from "~/api/proposal";
import { endorsementApi } from "~/api/endorsement";
import type { AxiosError } from "axios";
import type { SiriusError } from "~/utils/error";
import { useContractStore } from "../contract.store";
import { useTicketsStructure } from "./ticket.structure";

export const definePolicyStore = (policyType: PolicyType) => () => {
  // Todas informações da apólice
  const policy = ref<InternalPolicy>();
  const contractId = ref<number>();
  const policyOrigin = computed(() => {
    if (policy.value?.endorsement) return policy.value.endorsement.origin;
    return undefined;
  });
  const contractStore = useContractStore();
  const queryClient = useQueryClient();

  const {
    analyzeTicketReceipt,
    createTicket,
    deleteTicket,
    deleteTicketReceipt,
    loadTickets,
    sendTicketReceipt,
    tickets,
    updateTicket,
  } = useTicketsStructure();

  watch(
    () => policy.value?.status,
    (newStatus, oldStatus) => {
      if (!oldStatus) return;

      const panelContent = document.querySelector(".panel__content");
      if (panelContent) {
        panelContent.scrollTop = 0;
      }
    }
  );

  const isLoadingPolicy = ref(false);

  // Preenche o state com as informações da apólice
  async function loadPolicy(_contractId: number) {
    try {
      if (_contractId === 0) return;
      isLoadingPolicy.value = true;

      const hiringFlow = await hiringFlowApi.get(
        _contractId,
        policyType,
        useNuxtApp().$auth.role.value
      );

      contractId.value = _contractId;

      if (!hiringFlow) {
        isLoadingPolicy.value = false;
        return;
      }

      // Significa que é uma COTAÇÃO INTERNA
      if (hiringFlow.type === "contract_quote") {
        policy.value = {
          status: getPolicyStatusByQuotation(hiringFlow.contract_quote),
          quote: {
            ...hiringFlow.contract_quote,
            coverage_details:
              policyType === PolicyType.GERAL
                ? quoteCoverageDetails.rcg
                : quoteCoverageDetails.rcp,
          },
          proposal: hiringFlow?.contract_quote?.proposal || undefined,
        };
        isLoadingPolicy.value = false;
        return;
      }
      // Significa que já existe endosso
      if (hiringFlow.type === "endorsement") {
        const { endorsement } = hiringFlow;

        // Carrega a estrutura dos boletos (quando é interno)
        loadTickets(endorsement.id, endorsement.payments || []);
        // Se for endosso INTERNO
        if (endorsement.origin == "internal") {
          policy.value = {
            status: getPolicyStatusByEndorsement(endorsement),
            endorsement,
          };
          isLoadingPolicy.value = false;
          return;
        }
        // Se for endosso EXTERNO
        if (endorsement.origin == "external") {
          policy.value = {
            status: getPolicyStatusByEndorsement(endorsement),
            endorsement,
          };
          isLoadingPolicy.value = false;
          return;
        }
      }
    } catch (_error) {
      const error = _error as AxiosError<SiriusError>;
      const errorData = error?.response?.data;
      // Tratamento para os erros
      if (errorData?.error_code) {
        // Apólice isenta por contrato isento
        if (errorData.error_code === "contract_does_not_require_policy") {
          policy.value = {
            status: PolicyStatus.EXEMPT,
          };
          isLoadingPolicy.value = false;
          return;
        }
        // Erros ocasionais (mostra pro usuário)
        if (canShowErrorForUser(_error)) {
          policy.value = {
            status: PolicyStatus.EMPTY,
          };
          isLoadingPolicy.value = false;
          return;
        }
      }
      // Erros não previstos
      isLoadingPolicy.value = false;
      policy.value = {
        status: PolicyStatus.EMPTY,
      };
      const errorMessage = `Erro ao buscar informações da apólice ${policyType}!`;
      useNuxtApp().$error({
        error,
        message: errorMessage,
        extra: {
          contractId: _contractId,
        },
      });
    }
  }

  // Carrega a apólice com status de isenta
  function loadExemptPolicy() {
    policy.value = {
      status: PolicyStatus.EXEMPT,
    };
  }

  // Carrega a apólice com status de isenta
  function loadPolicyForExpiredContract() {
    policy.value = {
      status: PolicyStatus.EMPTY,
    };
  }

  // Reseta a tabela de contratos
  async function resetContractsTables() {
    await queryClient.invalidateQueries({
      queryKey: ["contracts"],
    });
    await queryClient.invalidateQueries({
      queryKey: [`contracts-${contractId.value}`],
    });
  }

  // Contrata a cotação
  async function contractQuotation() {
    try {
      if (!policy.value?.quote) return;
      const response = await proposalApi.proposalByQuote(
        policy.value.quote.id,
        useNuxtApp().$auth.role.value
      );
      policy.value.proposal = response.data;
      await resetContractsTables();
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;

      useNuxtApp().$error({
        error,
        message: "Erro ao contratar cotação!",
        extra: {
          quote: policy.value?.quote,
        },
      });
      throw error;
    }
  }

  // Envia a apólice da cotação aprovada
  async function contractProposal(data: SendPolicyFormFields) {
    try {
      if (!policy.value?.proposal) return;
      const response = await endorsementApi.createInternalEndorsement(
        {
          end_date: data.term_end || "",
          start_date: data.term_start || "",
          pdf_file: data.policy,
          endorsement_number: data.endorsement_number || "",
        },
        policy.value?.proposal?.id,
        useNuxtApp().$auth.role.value
      );
      policy.value.endorsement = response.data;

      // TODO: Adicionar estágio de análise no futuro
      policy.value.status = PolicyStatus.ADEQUATE;
      await resetContractsTables();
      useNuxtApp().$toast("Apólice enviada!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;

      useNuxtApp().$error({
        error,
        message: "Erro ao enviar apólice!",
        extra: {
          proposal: policy.value?.proposal,
        },
      });
      throw error;
    }
  }

  // Apaga o arquivo de endosso (apólice)
  async function deleteEndorsementFile(reason?: string) {
    try {
      if (!policy.value?.endorsement) return;
      const { endorsement } = policy.value;
      const endorsementId = endorsement.id;
      const endormentOrigin = endorsement.origin;
      if (endormentOrigin === "internal") {
        /**
         * Exclui toda instância do endosso utilizando a RECUSA,
         * voltando para o status de PENDING
         */
        await endorsementApi.evaluateEndorsement(
          endorsementId,
          "refused",
          reason || "Exclusão do arquivo"
        );
      } else if (endormentOrigin === "external") {
        await endorsementApi.deleteExternalEndorsement(endorsementId);
      }

      await loadPolicy(contractId.value || 0);

      await resetContractsTables();
      useNuxtApp().$toast("Apólice excluída!", {
        type: "warning",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao excluir apólice!",
        extra: {
          policy: policy.value?.endorsement,
        },
      });
    }
  }

  // Substitui o arquivo da apólice
  async function replaceEndorsementFile(endorsementFile: File) {
    try {
      if (!policy.value?.endorsement) return;
      const { endorsement } = policy.value;
      const endorsementId = endorsement.id;
      const endorsementFileId =
        endorsement.endorsement_files[0].endorsement_file_id;
      // Apaga o arquivo do endosso
      await endorsementApi.deleteEndorsementFile(
        endorsementId,
        endorsementFileId
      );
      // Envia o novo arquivo do endosso
      const uploadResponse = await endorsementApi.uploadEndorsementFile(
        endorsementId,
        endorsementFile
      );
      policy.value.endorsement = uploadResponse.data;
      await resetContractsTables();
      useNuxtApp().$toast("Apólice substituída com sucesso!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao substituir apólice!",
        extra: {
          policy: policy.value?.endorsement,
        },
      });
    }
  }

  // Recusa a proposta de cotação
  async function refuseProposal() {
    try {
      if (!policy.value) return;
      policy.value.status = PolicyStatus.REFUSAL;
      useNuxtApp().$toast("Proposta recusada!", {
        type: "warning",
      });
    } catch (error) {
      useNuxtApp().$error({
        error,
        message: "Erro ao recusar proposta!",
      });
    }
  }

  // Limpa todas informações da apólice
  function clearPolicy() {
    policy.value = undefined;
  }

  // Envia uma apólice externa
  async function sendUmbrella(data: SendPolicyFormFields) {
    const contract = contractStore.contract;
    const companyId = contract?.company_id;
    if (!companyId || !policy.value) return;
    try {
      const response = await endorsementApi.createExternalEndorsementForCompany(
        companyId,
        {
          end_date: data.term_end || "",
          start_date: data.term_start || "",
          pdf_file: data.policy as File,
          policy_type: policyType,
          policy_number: data.endorsement_number || "",
        },
        useNuxtApp().$auth.role.value
      );
      policy.value.endorsement = response.data;
      policy.value.status = PolicyStatus.ANALYZE;
      await resetContractsTables();
      useNuxtApp().$toast("Apólice externa enviada, aguardando análise!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao enviar apólice externa!",
        extra: { companyId, policy: policy.value?.endorsement },
      });
    }
  }

  // Aprova a apólice existente enviada
  async function approveUmbrella() {
    const endorsement = policy.value?.endorsement;
    try {
      if (!endorsement || !policy.value) return;
      const response = await endorsementApi.evaluateEndorsement(
        endorsement.id,
        "approved"
      );
      policy.value.endorsement = response.data;
      policy.value.status = PolicyStatus.ADEQUATE;
      await resetContractsTables();
      useNuxtApp().$toast("Apólice aprovada!", {
        type: "success",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao aprovar apólice!",
        extra: { policy: endorsement },
      });
    }
  }

  // Recusa a apólice existente enviada
  async function refuseUmbrella(reason?: string) {
    const endorsement = policy.value?.endorsement;
    if (!endorsement || !policy.value) return;
    try {
      await endorsementApi.evaluateEndorsement(
        endorsement.id,
        "refused",
        reason
      );
      await loadPolicy(contractId.value || 0);
      await resetContractsTables();
      useNuxtApp().$toast("Apólice recusada!", {
        type: "warning",
      });
    } catch (error) {
      if (canShowErrorForUser(error)) throw error;
      useNuxtApp().$error({
        error,
        message: "Erro ao recusar apólice!",
        extra: { policy: endorsement },
      });
    }
  }

  const showTickets = computed(() => {
    if (
      policy.value?.endorsement &&
      policy.value.endorsement.origin === "internal" &&
      policy.value.status === PolicyStatus.ADEQUATE
    ) {
      return true;
    }
    return false;
  });

  return {
    // Apólice
    policy,
    policyOrigin,
    loadPolicy,
    loadExemptPolicy,
    loadPolicyForExpiredContract,
    isLoadingPolicy,
    clearPolicy,
    // Cotação
    contractQuotation,
    // Proposta
    refuseProposal,
    contractProposal,
    // Arquivo da apólice
    deleteEndorsementFile,
    replaceEndorsementFile,
    // Apólice externa
    sendUmbrella,
    approveUmbrella,
    refuseUmbrella,
    // Toda estrutura dos boletos
    analyzeTicketReceipt,
    createTicket,
    deleteTicket,
    deleteTicketReceipt,
    loadTickets,
    sendTicketReceipt,
    tickets,
    updateTicket,
    showTickets,
  };
};
