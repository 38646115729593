import { type Endorsement, UserRoles } from "~/types";
import type { InternalQuotation } from "~/types/quotation";
import { canExecuteRequest } from ".";

type GetQuoteResponse =
  | {
      type: "contract_quote";
      contract_quote: InternalQuotation;
      success: true;
    }
  | {
      type: "endorsement";
      endorsement: Endorsement;
      success: true;
    };

/**
 * Retorna estado atual das apólices de um contrato
 */
export const hiringFlowApi = {
  get(contractId: number, rc: "geral" | "profissional", role?: UserRoles) {
    if (!canExecuteRequest()) return null;
    return useNuxtApp()
      .$api.get<GetQuoteResponse>(
        `/${role}/contract/${contractId}/current_hiring_flow/`,
        {
          params: {
            rc,
          },
        }
      )
      .then((response) => response.data);
  },
};
