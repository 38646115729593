export enum UserRoles {
  OPERATIONAL = "operator",
  SUPPLIER = "supplier",
  ADMIN = "admin",
}

export enum UserActions {
  GET_INFORMATION_FROM_ALL_USERS = "get_information_from_all_users",
  UPLOAD_CONTRACT_SPREADSHEET = "upload_contract_spreadsheet",
  GET_CONTRACT_INFORMATION_FROM_SUPPLIERS = "get_contract_information_from_suppliers",
  DOWNLOAD_PROPOSAL = "download_proposal",
  GET_COMPANY_INFORMATION_FROM_SUPPLIERS = "get_company_information_from_suppliers",
  GET_QUOTE_BY_CONTRACT = "get_quote_by_contract",
  CREATE_PROPOSAL = "create_proposal",
  CREATE_ENDORSEMENT = "create_endorsement",
  UPDATE_ENDORSEMENT = "update_endorsement",
  FILE_DOWNLOAD = "file_download",
  FILE_UPLOAD = "file_upload",
  FILE_DELETE = "file_delete",
  EVALUATE_ENDORSEMENT = "evaluate_endorsement",
  CREATE_EXTERNAL_UMBRELLA_POLICY = "create_external_umbrella_policy",
  DELETE_EXTERNAL_ENDORSEMENT = "delete_external_endorsement",
  VIEW_EXTERNAL_ENDORSEMENTS_BY_COMPANY_ID = "view_external_endorsements_by_company_id",
}

/**
 * Foi necessária a criação desse objeto para controle de permissões,
 * no back possui um tipo de agrupamento de permissões para utilizar a mesma em várias ações,
 * sendo assim criei um tipo de "desagrupamento" para caso as
 * permissões venham a ser alteradas no futuro
 */
export const Actions = {
  // Permissões de excel
  UPLOAD_EXCEL: [UserActions.UPLOAD_CONTRACT_SPREADSHEET], // OK
  // Permissões de empresas
  GET_ALL_COMPANIES: [UserActions.GET_COMPANY_INFORMATION_FROM_SUPPLIERS], // OK
  GET_COMPANY_BY_ID: [UserActions.GET_COMPANY_INFORMATION_FROM_SUPPLIERS], // OK
  // Permissões de contrato
  GET_ALL_CONTRACTS: [UserActions.GET_CONTRACT_INFORMATION_FROM_SUPPLIERS], // OK
  GET_CONTRACT_BY_ID: [UserActions.GET_CONTRACT_INFORMATION_FROM_SUPPLIERS], // OK
  GET_CONTRACTS_BY_COMPANY: [
    UserActions.GET_CONTRACT_INFORMATION_FROM_SUPPLIERS,
  ], // OK
  GET_CONTRACTS_BY_ENDORSEMENT: [
    UserActions.GET_CONTRACT_INFORMATION_FROM_SUPPLIERS,
  ], // OK
  // Permissões de informações da apólice
  GET_CURRENT_HIRING_FLOW: [UserActions.GET_QUOTE_BY_CONTRACT], // OK
  // Permissões de endosso interno
  CREATE_INTERNAL_PROPOSAL: [UserActions.CREATE_PROPOSAL], // OK
  CREATE_INTERNAL_ENDORSEMENT: [UserActions.CREATE_ENDORSEMENT], // OK
  DELETE_INTERNAL_ENDORSEMENT: [UserActions.EVALUATE_ENDORSEMENT], // OK
  // Permissões de endosso externo
  CREATE_EXTERNAL_ENDORSEMENT: [UserActions.CREATE_EXTERNAL_UMBRELLA_POLICY], // OK
  GET_ALL_EXTERNAL_ENDORSEMENTS: [
    UserActions.VIEW_EXTERNAL_ENDORSEMENTS_BY_COMPANY_ID,
  ], // OK
  DELETE_EXTERNAL_ENDORSEMENT: [UserActions.DELETE_EXTERNAL_ENDORSEMENT], // OK
  // Permissões de arquivo de endosso
  UPDATE_ENDORSEMENT_FILE: [UserActions.FILE_UPLOAD], // OK
  DELETE_ENDORSEMENT_FILE: [UserActions.FILE_DELETE], // OK
  REPLACE_ENDORSEMENT_FILE: [UserActions.FILE_DELETE, UserActions.FILE_UPLOAD], // OK
  // Permissões de análise de endosso
  EVALUATE_ENDORSEMENT: [UserActions.EVALUATE_ENDORSEMENT], // OK
  // Permissões de download de arquivos
  DOWNLOAD_ENDORSEMENT: [UserActions.FILE_DOWNLOAD], // OK
  DOWNLOAD_PROPOSAL: [UserActions.DOWNLOAD_PROPOSAL], // OK
};
