import type {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import type { Pagination, PaginationResponse } from "~/types";

export const makePaginationRequest = (
  pagination: Pagination,
  extraParams?: { [key: string]: any }
): AxiosRequestConfig => {
  return {
    params: {
      page: pagination.page,
      limit: pagination.itemsPerPage,
      ...(pagination.sortBy && pagination.sortType
        ? {
            "sort[]": `${pagination.sortBy},${pagination.sortType}`,
          }
        : {}),
      ...(pagination.search ? { search: pagination.search } : {}),
      ...(extraParams ? extraParams : {}),
    },
  };
};

interface PaginateHeaders extends AxiosRequestHeaders {
  "current-page": number;
  "page-items": number;
  "total-pages": number;
  "total-count": number;
}

export const makePaginationResponse = <
  TypeItem = any,
  TSerializeData = { [key: string]: TypeItem[] }
>(
  response: AxiosResponse<TSerializeData>,
  serialize: (data: TSerializeData) => TypeItem[]
) => {
  const headers = response.headers as PaginateHeaders;
  const items = serialize(response.data);

  return {
    items,
    page: Number(headers["current-page"]),
    totalItems: Number(headers["total-count"]),
  } as PaginationResponse<TypeItem[]>;
};
