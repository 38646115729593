import type { InternalQuotation } from "~/types/quotation";
import { PolicyStatus } from "~/types";
import { EndorsementStatus, type Endorsement } from "~/types/endorsement";
import { InternalProposalStatus } from "~/types/proposal";
import type { RefinementCtx } from "zod";
import { isBefore, isFuture } from "date-fns";

export const getPolicyStatusByQuotation = (
  quotation: InternalQuotation
): PolicyStatus => {
  // Se não tiver proposta
  if (!quotation.proposal) {
    return PolicyStatus.PENDING;
  }
  if (quotation.proposal) {
    // Se a proposta foi enviada e aprovada, e ainda não tem apólice
    if (
      quotation.proposal.proposal_status === InternalProposalStatus.APPROVED &&
      !quotation.proposal.endorsement
    ) {
      return PolicyStatus.EMISSION;
    }

    // Se a proposta foi enviada e recusada
    if (quotation.proposal.proposal_status === InternalProposalStatus.REFUSED) {
      return PolicyStatus.REFUSAL;
    }
  }
  return PolicyStatus.PENDING;
};

export const getPolicyStatusByEndorsement = (
  endorsement: Endorsement
): PolicyStatus => {
  switch (endorsement.policy_status) {
    case EndorsementStatus.APPROVED:
      return PolicyStatus.ADEQUATE;
    case EndorsementStatus.REFUSED:
      return PolicyStatus.REFUSAL;
    case EndorsementStatus.UNDER_ANALYSIS:
      return PolicyStatus.ANALYZE;
    case EndorsementStatus.EXPIRED:
      return PolicyStatus.EXPIRED;
    default:
      return PolicyStatus.REFUSAL;
  }
};

export const validateEndorsementEndDate = (
  ctx: RefinementCtx,
  termEnd?: string,
  termStart?: string,
  isAdmin?: boolean,
) => {
  try {
    if (!termEnd) return;

    const termEndDate = parseDate(termEnd);

    // Precisa ser no futuro
    if (!isAdmin && termEndDate) {
      if (!isFuture(termEndDate)) {
        ctx.addIssue({
          code: "custom",
          message: "O fim da vigência precisa ser no futuro.",
        });
      }
    }
    // Precisa ser posterior ao início da vigência.
    if (termEndDate && termStart) {
      const termStartDate = parseDate(termStart);
      if (isBefore(termEndDate, termStartDate)) {
        ctx.addIssue({
          code: "custom",
          message: "Precisa ser posterior ao início da vigência.",
        });
      }
    }
  } catch (error) {
    console.log("Erro ao validar fim da vigência", error);
  }
};
