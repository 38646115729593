export function canExecuteRequest() {
  const isConnected = useNuxtApp().$auth?.role?.value;
  return !!isConnected;
}

export function convertObjectToFormData(obj: { [key: string]: any }): FormData {
  const formData = new FormData();
  for (const key in obj) {
    formData.append(key, obj[key]);
  }
  return formData;
}
