export const arrayUtils = {
  dynamicSort(property: string) {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substring(1);
    }
    return function (a: any, b: any) {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  },
  existInArray<T = string>(value: T, arr: T[]) {
    return arr.includes(value);
  },
  removeDuplicates<T = string | number>(arr: T[]) {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  },
};
