export enum TicketStatus {
  PAID = "paid",
  OVERDUE = "overdue",
  ANALYZE = "analyze",
  DUE = "due",
}

export interface TicketOld {
  id?: number;
  ticket_link?: string;
  ticket?: File;
  status: TicketStatus;
  due_date: string;
  value: number;
  receipt?: string | File;
}

export enum TicketPaymentStatus {
  PENDING = "pending",
  OVERDUE = "overdue",
  ANALYSIS = "analysis",
  PAID = "paid",
}
export interface TicketFile {
  filename: string;
  created_at: string;
  file_id: string;
}
export interface Ticket {
  // ID do Boleto
  id: number;
  // ID do endosso
  endorsement_id: number;
  // Código de barras
  bar_code: null | string;
  // Arquivo do boleto
  boleto_file?: TicketFile | null;
  // Data de vencimento
  due_date: null | string;
  // Valor do boleto
  value: number;
  // Número de parcela atual
  installment_number: null | number;
  // Total de parcelas
  total_installments: null | number;
  // Status do pagamento do boleto
  payment_status: TicketPaymentStatus;
  // Arquivo do recibo
  receipt_file?: TicketFile | null;
  payment_type: "boleto";
  migrated_from_old_db: null | string;
  created_at: string;
  updated_at: string;
}

export interface CreateTicketData {
  boleto_file: File;
  value: number;
  due_date: string;
  payment_status: TicketPaymentStatus;
  receipt_file?: File;
}

export interface UpdateTicketData {
  boleto_file?: File;
  value?: number;
  due_date?: string;
  payment_status?: TicketPaymentStatus;
  receipt_file?: File;
}

export interface SendReceiptData {
  receipt_file: File;
}

export interface AnalyzeReceiptData {
  analysis_conclusion: "refused" | "approved";
  reason?: string;
}
