import { type Company } from "~/types";
import { useRoute } from "vue-router";

export const useSupplierStore = defineStore("supplier-store", () => {
  const route = useRoute();

  // Dados do fornecedor
  const supplier = ref<Company>();
  const pendingContracts = ref<any[]>();

  const isLoading = ref(false);

  // Bate na API para buscar informações do fornecedor
  async function loadSupplier() {
    try {
      isLoading.value = true;
      supplier.value = {
        id: 25,
        company_name: "Zentix Wallace Malta",
        cnpj: "20864204560221",
        active_contracts: 84,
        pending_contracts: 11,
        status: "overdue",
        contact: {
          name: "Benita Barton",
          email: "benitabarton@zentix.com",
          phone_number: "99999999999",
        },
        additional_contact: {
          name: "Sherri Roach",
          email: "sherriroach@zentix.com",
          phone_number: "94397429330",
        },
        address: {
          state: "",
          city: "",
          street: "Manoel Borba Gato, 100, Guarulhos",
          zip_code: "07044220",
          number: "234",
          complement: "",
        },
      };
    } catch (error) {
    } finally {
      isLoading.value = false;
    }
  }

  const isLoadingPendingContracts = ref(true);

  // Carrega os contratos pendentes do fornecedor
  async function fetchPendingContracts() {
    try {
      isLoadingPendingContracts.value = true;
      await delay(1200);
      if (route.query.action === "clear") {
        isLoadingPendingContracts.value = false;
        pendingContracts.value = undefined;
        return;
      }
      pendingContracts.value = [];
    } catch (error) {
    } finally {
      isLoadingPendingContracts.value = false;
    }
  }

  return {
    isLoading,
    supplier,
    loadSupplier,
    pendingContracts,
    fetchPendingContracts,
    isLoadingPendingContracts,
  };
});
