import { type InternalProposal, UserRoles } from "~/types";

export const proposalApi = {
  proposalByQuote(quoteId: number, role?: UserRoles) {
    return useNuxtApp().$api.post<InternalProposal>(
      `/${role}/proposal/${quoteId}/create`
    );
  },
  /**
   * Baixa o arquivo RASCUNHO da proposta
   */
  downloadProposalSketch(quoteId: number, role?: UserRoles) {
    return useNuxtApp().$api.post<any>(
      `/${role}/quote/${quoteId}/download`,
      undefined,
      {
        responseType: "blob",
      }
    );
  },
  /**
   * Baixa o arquivo de proposta
   */
  downloadProposal(quoteId: number, role?: UserRoles) {
    return useNuxtApp().$api.post<any>(
      `/${role}/proposal/${quoteId}/download`,
      undefined,
      {
        responseType: "blob",
      }
    );
  },
};
