import axios, { AxiosError } from "axios";
import useEnv from "~/composables/useEnv";
import { disconnectStatus } from "./auth";

export default defineNuxtPlugin({
  name: "api",
  enforce: "pre",
  setup(nuxtApp) {
    const api = axios.create({
      baseURL: useEnv("API_URL") || "",
    });

    api.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        // Desconecta o usuário caso dê erro de sessão inválida
        // Com tanto que não seja a rota de logout
        if (!error.response?.config.url?.includes("logout")) {
          const errorStatus = error.response?.status;
          if (errorStatus && disconnectStatus.includes(errorStatus)) {
            await useNuxtApp().$auth.handleLogout(true);
            setTimeout(() => {
              useNuxtApp().$toast("Sessão expirada, acesse novamente!", {
                type: "warning",
                autoClose: 3100,
                closeOnClick: true,
              });
            }, 600);
          }
        }
        throw error;
      }
    );

    return {
      provide: {
        api,
      },
    };
  },
});
