import type {
  RouteLocationNormalized,
  RouteParams,
  RouteRecordName,
} from "vue-router";

/**
 * PQ NÃO TEM COMO PEGAR A ROTA ANTERIOR NO NUXT
 */
export const usePreviousRouteStore = defineStore("previous-route", () => {
  const previousRoute = ref<{
    name: string | RouteRecordName;
    path: string;
    params?: RouteParams;
  }>();

  const setPreviousRoute = (value: RouteLocationNormalized) => {
    previousRoute.value = {
      name: value.name || "",
      path: value.path,
      params: value.params,
    };
  };

  return {
    previousRoute,
    setPreviousRoute,
  };
});
