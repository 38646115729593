import {
  type CreateTicketData,
  type UpdateTicketData,
  type SendReceiptData,
  type AnalyzeReceiptData,
  type Ticket,
} from "@/types";
import { convertObjectToFormData } from "./utils";

export const ticketsApi = {
  // Cria um boleto para um endosso interno
  createTicket(endorsementId: number, data: CreateTicketData) {
    const formData = convertObjectToFormData(data);
    return useNuxtApp().$api.post<Ticket>(
      `/operator/endorsement/${endorsementId}/boleto`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  // Atualiza as informações de um boleto
  updateTicket(
    endorsementId: number,
    paymentId: number,
    data: UpdateTicketData
  ) {
    const formData = convertObjectToFormData(data);
    return useNuxtApp().$api.put<Ticket>(
      `/operator/endorsement/${endorsementId}/boleto/${paymentId}`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  // Exclui um boleto
  deleteTicket(endorsementId: number, paymentId: number) {
    return useNuxtApp().$api.delete(
      `/operator/endorsement/${endorsementId}/boleto/${paymentId}`
    );
  },
  // Envia um recibo para um boleto
  sendReceipt(endorsementId: number, paymentId: number, data: SendReceiptData) {
    const formData = convertObjectToFormData(data);
    return useNuxtApp().$api.post<Ticket>(
      `/operator/endorsement/${endorsementId}/boleto/${paymentId}/receipt_file`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  // Exclui um arquivo de recibo
  deleteReceiptFile(
    endorsementId: number,
    paymentId: number,
    receiptFileId: string
  ) {
    return useNuxtApp().$api.delete<boolean | Ticket>(
      `/operator/endorsement/${endorsementId}/boleto/${paymentId}/receipt_file/${receiptFileId}`
    );
  },
  // Analisa um recibo
  analyzeReceipt(
    endorsementId: number,
    paymentId: number,
    data: AnalyzeReceiptData
  ) {
    const formData = convertObjectToFormData(data);
    return useNuxtApp().$api.post<Ticket>(
      `/operator/endorsement/${endorsementId}/boleto/${paymentId}/receipt_file/analyze`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
};
