<template>
  <NuxtLayout>
    <div id="toast-container"></div>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { siteDefaultSeo } from "./utils/seo";
import "@vuepic/vue-datepicker/dist/main.css";

useSeoMeta(siteDefaultSeo);

useHead({
  link: [{ rel: "icon", type: "image/png", href: "/favicon.png" }],
});
</script>
