import type { Endorsement } from "./endorsement";
import type { InternalProposal } from "./proposal";
import type { InternalQuotation } from "./quotation";
import type { TicketOld } from "./ticket";

export interface PolicyExempt {
  authorization_date: string;
  reason: string;
  exemption?: any;
}

export interface PolicyQuotation {
  coverages: string[];
  term_start: string;
  term_end: string;
  iof_value: number;
  award_total_value: number;
  award_net_value: number;
}

export enum OldPolicyStatus {
  // Já integrado
  EXEMPT = "exempt",
  // Ainda será adicionado
  ADEQUATE = "adequate",
  PENDING = "pending",
  OVERDUE = "overdue",
  CALCULATE = "calculate",
  EMISSION = "emission",
  ANALYZE = "analyze",
  REFUSAL = "refusal",
  CANCELED = "canceled",
  EXEMPTION = "exemption",
  PAYMENT = "payment",
}
export interface Policy {
  status: PolicyStatus;
  exempt?: PolicyExempt;
  tickets?: TicketOld[];
  policy_link?: string;
  quotation?: PolicyQuotation;
  quote?: InternalQuotation;
}

export enum PolicyStatus {
  // Já integrado
  EXEMPT = "exempt",
  // Ainda será adicionado
  ADEQUATE = "adequate",
  PENDING = "pending",
  EXPIRED = "expired",
  EMISSION = "emission",
  ANALYZE = "analyze",
  REFUSAL = "refusal",
  EMPTY = "empty",
}
export enum PolicyType {
  GERAL = "geral",
  PROFISSIONAL = "profissional",
}
export interface InternalPolicy {
  status: PolicyStatus;
  quote?: InternalQuotation;
  proposal?: InternalProposal;
  endorsement?: Endorsement;
}
