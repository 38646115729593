import type { Ticket } from "~/types";

// Solução para o problema dos arquivos virem como objeto vazio
export const serializeTicket = (_ticket: Ticket) => {
  const ticket = clone<Ticket>(_ticket);
  /**
   * ARQUIVO DO BOLETO
   */
  if (ticket.boleto_file === null) {
    ticket.boleto_file = undefined;
  }
  if (
    typeof ticket.boleto_file === "object" &&
    Object.keys(ticket.boleto_file).length === 0
  ) {
    ticket.boleto_file = undefined;
  }
  /**
   * RECIBOS
   */
  if (ticket.receipt_file === null) {
    ticket.receipt_file = undefined;
  }
  if (
    typeof ticket.receipt_file === "object" &&
    Object.keys(ticket.receipt_file).length === 0
  ) {
    ticket.receipt_file = undefined;
  }
  return ticket;
};
