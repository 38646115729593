export enum InternalProposalStatus {
  APPROVED = "approved",
  REFUSED = "refused",
}

export interface InternalProposal {
  id: number;
  contract_quote_id: number;
  to_send: boolean;
  sent: boolean;
  start_date: string;
  end_date: string;
  proposal_status: InternalProposalStatus;
  proposal_status_description: string;
  endorsement_number: null | number;
  created_at: string;
  updated_at: string;
  endorsement: null | any;
}
