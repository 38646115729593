import type { RequestTokenData, LoginData } from "~/types/auth";

export const authApi = {
  requestToken(data: RequestTokenData, recaptcha?: string) {
    return useNuxtApp().$api.post<{ token?: string }>(
      `/request_authentication_token`,
      data,
      {
        params: { recaptcha },
        withCredentials: true,
      }
    );
  },
  login(data: LoginData, recaptcha?: string) {
    return useNuxtApp().$api.post<{
      message: string;
      success: boolean;
    }>(`/login`, data, {
      params: { recaptcha },
      withCredentials: true,
    });
  },
  logout() {
    return useNuxtApp().$api.delete(`/logout`, {
      withCredentials: true,
    });
  },
};
