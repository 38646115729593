import type { AxiosError } from "axios";
import { addHours } from "date-fns";
import { jwtDecode } from "jwt-decode";
import { authApi } from "~/api/auth";
import { useSupplierStore } from "~/stores";
import {
  UserRoles,
  type RequestTokenData,
  type Session,
  type UserActions,
  type LoginData,
} from "~/types/auth";

export interface CheckUserAcessProps {
  roles?: UserRoles[] | UserRoles;
  actions?: UserActions[] | UserActions;
}

export const disconnectStatus = [401, 403];

export default defineNuxtPlugin({
  name: "auth",
  dependsOn: ["api"],
  async setup(nuxtApp) {
    const session = useCookie<Session | undefined>("session", {
      expires: addHours(new Date(), 12),
      maxAge: 60 * 60 * 12, // 12 horas em segundos
    });

    const user = computed(() => session.value?.user);
    const role = computed(() => session.value?.user?.role);

    const queryClient = useQueryClient();

    const setApiHeader = (token?: string) => {
      if (token) {
        useNuxtApp().$api.defaults.headers.common["authorization"] = token;
      } else {
        delete useNuxtApp()?.$api.defaults.headers.common["authorization"];
      }
    };

    /**
     * Carrega a sessão do usuário caso exista
     */
    const loadAuthentication = async () => {
      if (session.value) {
        setApiHeader(session.value.token);

        if (session.value.user.role === UserRoles.SUPPLIER) {
          await useSupplierStore().loadSupplier();
        }
      } else {
        setApiHeader();
      }
    };
    loadAuthentication();

    /**
     * Verifica se o usuário está autenticado
     */
    const checkAuthentication = () => {
      return !!session.value;
    };

    /**
     * Envia requisição para pedir o email do token
     */
    const handleOperationalRequestToken = async (
      body: RequestTokenData,
      recaptcha: string
    ) => {
      try {
        const response = await authApi.requestToken(body, recaptcha);

        return response.data.token;
      } catch (_error) {
        const error = _error as AxiosError;
        const status = error.response?.status;
        if (status && status >= 400 && status <= 500) {
          useNuxtApp().$toast("Os dados são inválidos!", {
            type: "error",
          });
          throw _error;
        }
        useNuxtApp().$error({
          error,
          message: "Erro ao enviar token de login!",
          extra: {
            ...body,
          },
        });
        throw _error;
      }
    };

    /**
     * Envia requisição para pedir o email do token
     */
    const handleLogin = async (
      body: LoginData,
      recaptcha: string,
      throwError?: boolean
    ) => {
      try {
        const response = await authApi.login(body, recaptcha);
        const jwt = response.headers["authorization"];

        if (jwt) {
          const user = jwtDecode<{
            actions: UserActions[];
            email: string;
            role: UserRoles;
            cnpj?: string;
            name?: string;
          }>(jwt);

          if (user) {
            session.value = {
              token: jwt,
              user: {
                actions: user.actions,
                email: user.email,
                role: user.role,
                cnpj: user.cnpj,
                name: user.name,
              },
            };
            setApiHeader(jwt);

            if (user.role === UserRoles.SUPPLIER) {
              await useSupplierStore().loadSupplier();
            }

            await navigateTo("/contratos");
            return body.user.token;
          }
        }
      } catch (_error) {
        const error = _error as AxiosError;
        const status = error.response?.status;
        if (status && status >= 500) {
          useNuxtApp().$error({
            error,
            message: "Erro ao realizar login!",
            extra: {
              ...body.user,
            },
          });
          if (throwError) throw _error;
          return false;
        }
        if (throwError) throw _error;
        return false;
      }
    };

    /**
     * Encerra a sessão
     */
    const handleLogout = async (onlyClearSession?: boolean) => {
      try {
        if (!onlyClearSession) {
          try {
            await authApi.logout();
          } catch (error) {
            console.error(error);
          }
        }
        session.value = undefined;
        setApiHeader();
        navigateTo("/entrar");
        setTimeout(() => {
          queryClient.invalidateQueries();
        }, 1500);
      } catch (error) {}
    };

    /**
     * Verifica se é permitido para o usuário
     */
    const checkUserAccess = ({ actions, roles }: CheckUserAcessProps) => {
      if (!roles && !actions) return true;
      if (!user.value) return false;

      if (roles) {
        if (Array.isArray(roles) && roles.length) {
          if (arrayUtils.existInArray(user.value.role, roles) === false)
            return false;
        } else if (typeof roles === "string") {
          if (user.value.role !== roles) return false;
        }
      }

      if (actions) {
        if (Array.isArray(actions) && actions.length) {
          for (const action of actions) {
            if (arrayUtils.existInArray(action, user.value.actions) === false)
              return false;
          }
        } else if (typeof actions === "string") {
          if (arrayUtils.existInArray(actions, user.value.actions) === false)
            return false;
        }
      }

      return true;
    };

    return {
      provide: {
        auth: {
          user,
          role,
          loadAuthentication,
          checkAuthentication,
          handleOperationalRequestToken,
          handleLogin,
          handleLogout,
          checkUserAccess,
        },
      },
    };
  },
});
