import type { PolicyType, Ticket } from ".";

export enum EndorsementStatus {
  APPROVED = "approved",
  REFUSED = "refused",
  UNDER_ANALYSIS = "under_analysis",
  EXPIRED = "expired",
  ERRO = "erro",
}

export interface Endorsement {
  id: number;
  start_date: string;
  end_date: string;
  subgroup_code: string;
  cnpj: string;
  origin: "internal" | "external";
  contract_value: number;
  process_title: string;
  process: string;
  provider: string;
  endorsement_number: null | number;
  deleted_at: null | string;
  policy_type: PolicyType;
  policy_status: EndorsementStatus;
  policy_status_description: string;
  company_id: number;
  contract_id: number;
  proposal_id: number;
  created_at: string;
  updated_at: string;
  document_number?: number 
  migrated_from_old_db?: null | string;
  custom_creation?: null | string;
  endorsement_files: {
    filename: string;
    created_at: string;
    endorsement_file_id: string;
  }[];
  // Boletos
  payments?: Ticket[];
}


export interface CreateInternalEndorsement {
  policy_type: string
  start_date: string
  end_date: string
  migrated_from_old_db: boolean
  value: string
  iof: string
  premium_value: string
  document_number: string
  endorsement_number: string
  policy_number: string  
  endorsement_pdf_file: File
}