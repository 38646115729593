import { default as indexzUX7QfII2oMeta } from "/app/pages/configuracoes/endossos-internos/index.vue?macro=true";
import { default as indexlIVSPI3FnjMeta } from "/app/pages/configuracoes/index.vue?macro=true";
import { default as _91id_93w75hIdF2i1Meta } from "/app/pages/contratos/id/[id].vue?macro=true";
import { default as indexWh4rq9xITvMeta } from "/app/pages/contratos/index.vue?macro=true";
import { default as indexk19tkNXnzlMeta } from "/app/pages/contratos/pendentes/index.vue?macro=true";
import { default as _91cnpj_93nso0NhKme1Meta } from "/app/pages/empresas/cnpj/[cnpj].vue?macro=true";
import { default as indexpcSsp30LwRMeta } from "/app/pages/empresas/index.vue?macro=true";
import { default as _91token_93oFF1KJLS3EMeta } from "/app/pages/entrar/[token].vue?macro=true";
import { default as index3JoI9b624fMeta } from "/app/pages/entrar/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as index2BCueOD680Meta } from "/app/pages/relatorios/index.vue?macro=true";
export default [
  {
    name: "configuracoes-endossos-internos",
    path: "/configuracoes/endossos-internos",
    meta: indexzUX7QfII2oMeta || {},
    component: () => import("/app/pages/configuracoes/endossos-internos/index.vue")
  },
  {
    name: "configuracoes",
    path: "/configuracoes",
    meta: indexlIVSPI3FnjMeta || {},
    component: () => import("/app/pages/configuracoes/index.vue")
  },
  {
    name: "contratos-id-id",
    path: "/contratos/id/:id()",
    meta: _91id_93w75hIdF2i1Meta || {},
    component: () => import("/app/pages/contratos/id/[id].vue")
  },
  {
    name: "contratos",
    path: "/contratos",
    meta: indexWh4rq9xITvMeta || {},
    component: () => import("/app/pages/contratos/index.vue")
  },
  {
    name: "contratos-pendentes",
    path: "/contratos/pendentes",
    meta: indexk19tkNXnzlMeta || {},
    component: () => import("/app/pages/contratos/pendentes/index.vue")
  },
  {
    name: "empresas-cnpj-cnpj",
    path: "/empresas/cnpj/:cnpj()",
    meta: _91cnpj_93nso0NhKme1Meta || {},
    component: () => import("/app/pages/empresas/cnpj/[cnpj].vue")
  },
  {
    name: "empresas",
    path: "/empresas",
    meta: indexpcSsp30LwRMeta || {},
    component: () => import("/app/pages/empresas/index.vue")
  },
  {
    name: "entrar-token",
    path: "/entrar/:token()",
    meta: _91token_93oFF1KJLS3EMeta || {},
    component: () => import("/app/pages/entrar/[token].vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    meta: index3JoI9b624fMeta || {},
    component: () => import("/app/pages/entrar/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "relatorios",
    path: "/relatorios",
    meta: index2BCueOD680Meta || {},
    component: () => import("/app/pages/relatorios/index.vue")
  }
]