export const quoteCoverageDetails = {
  rcg: [
    "Prestação de Serviços em locais de terceiros",
    "Obras civis, instalação e montagem",
    "RC empregador",
    "RC subsidiária do segurado por produtos transportados de sua propriedade transportados por terceiros",
    "Danos materiais causados ao proprietário da obra",
    "RC fundações",
    "Erro de projeto (obras civis)",
    "RC cruzada (obras civis)",
    "Riscos contingentes",
    "Poluição súbita",
    "Brigada de incêndio e /ou serviços de segurança e/ou vigilância mantidos e/ou contratados pelo segurado",
    "Danos morais",
    "Custos de defesa",
    "Contenção de sinistros",
    "Perdas financeiras e/ou lucros cessantes",
  ],
  rcp: [
    "Responsabilidade Civil Profissional",
    "Responsabilidade Civil Solidária",
    "Dano Moral",
    "Difamação, Calúnia e Injuria",
    "Propriedade Intelectual",
    "Extravio, Roubo e Furto de Documentos",
    "Comparecimento ao tribunal",
    "Atos desosnestos de colaboradores",
    "Danos à Reputação",
  ],
};
